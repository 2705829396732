import { Dialog, Input, DialogBackdrop, DialogPanel, Button } from '@headlessui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import clsx from 'clsx/lite';
import { map } from 'lodash';
import { FC, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { pairState } from 'state/exchange';
import { useAddress } from 'state/wallet-state';
import { PairInfo } from 'types/pairs';
import { PaginationObserver } from './data-grid';
import { useNavigate } from 'react-router';
import { FaX } from 'react-icons/fa6';
import { FormattedNumber } from './formatted-number';

const PG_LIMIT = 100;

const ToggleSwitch: FC<{value: boolean; onChange: (value: boolean) => void; label: string}> = ({ value, onChange, label}) => <div className=' flex flex-row gap-1 items-center'>
  <div className={clsx(value ? 'bg-accent-btn' : 'bg-gr2', 'rounded-full relative h-4 w-6 p-0.5  cursor-pointer')} onClick={() => onChange(!value)}>
    <div className={clsx(value ? 'ml-2' : 'ml-0', 'bg-background3 size-3 rounded-full transition-all')} />
  </div>
  <p className="text-xs text-white">{label}</p>
</div>;

const PairOption: FC<{pair: PairInfo, onSelect: () => void}> = ({ pair, onSelect }) => {
  const address= useAddress();
  console.log(pair.volume_previous_24h ?? 1, Number(pair.volume_24h ?? '1'), ((pair.volume_previous_24h ?? 0) / Number(pair.volume_24h ?? '1')));
  return (
    <div
      key={pair.contract_addr}
      className={clsx(
        'hover:bg-gradient-to-r hover:from-theme-yellow1/80 transition',
        'bg-background2',
        'relative select-none p-2 text-white text-sm flex flex-col gap-2 items-center overflow-x-hidden box-border w-full'
      )}
      onClick={() => onSelect()}
    >
      <div className="flex flex-row w-full items-start">
        <div className='flex items-center'>
          <img src={pair.first_token.img} className="bg-background4 rounded-full !w-8 !h-8 p-1 ml-1 z-[1]" />
          <img src={pair.second_token.img} className="bg-background4 rounded-full !w-7 !h-7 p-1 ml-1 relative -left-3" />
        </div>
        <div className='flex flex-col !text-[10px] gap-1 w-full'>
          <div className='flex flex-row gap-1 items-center w-full'>
            <p className={clsx('font-logo font-bold text-nowrap whitespace-nowrap text-ellipsis min-w-0', !pair.verified && 'text-white/60')}>{pair.first_token.denom ?? pair.first_token.name}</p>
            <img src="/assets/svg/reverse-btn.svg" className='size-4' />
            <p className={clsx('font-logo font-bold text-nowrap whitespace-nowrap text-ellipsis min-w-0', !pair.verified && 'text-white/60')}>{pair.second_token.denom ?? pair.second_token.name}</p>
            {pair.dangerous && <p className="font-bold bg-theme-red/60 text-[10px] h-4 px-2 rounded-full center text-text-accent">Danger</p>}
            {pair.verified && <p className="font-bold bg-theme-yellow1/80 text-[10px] h-4 px-2 rounded-full center text-text-accent">Verified</p>}
            {pair.admin_address === address && <p className="font-bold bg-gr2 text-[10px] h-4 px-2 rounded-full center">Created</p>}
          </div>
          <div className="flex flex-row gap-1 items-start">
            <p className={clsx('rounded-full bg-gradient-to-r text-xs px-2 h-4 center font-bold w-fit',pair.first_token.type === 'native' ? 'from-theme-purple to-theme-purple2' : 'from-gr1 to-gr2')}>{pair.first_token.type}</p>
            <p className={clsx('rounded-full bg-gradient-to-l text-xs px-2 h-4 center font-bold w-fit',pair.second_token.type === 'native' ? 'from-theme-purple to-theme-purple2' : 'from-gr1 to-gr2')}>{pair.second_token.type}</p>
          </div>
          <FormattedNumber className="text-sm " prefix="Liquidity: " value={pair.liquidity_usd} suffix="$"/>
          <span className='text-sm flex'>
            <FormattedNumber className='mr-1' prefix="24h Volume: " value={Number(pair.volume_24h)} suffix="$"/> 
            {Number(pair.volume_24h) && pair.volume_previous_24h ? <>(<FormattedNumber className={clsx(Number(pair.volume_24h) < pair.volume_previous_24h ? 'text-theme-red' : 'text-theme-green', 'text-sm ')} prefix={Math.sign(Number(pair.volume_24h) - pair.volume_previous_24h) >= 0 ? '+' : '-'} value={Math.abs(Number(pair.volume_24h) - pair.volume_previous_24h)} suffix="$" />)</> : null}
          </span>
        </div>
      </div>
    </div>
  );
};

export const SearchBar: FC = () => {
  const degen = pairState.use.degen();
  const [search, setSearch] = useState('');
  const address= useAddress();

  const {data, fetchNextPage, hasNextPage} = useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const resp = await axios.get<PairInfo[]>(`${baseUrl}/degen/pairs`, { params: {
        q: search,
        // creator_address: address,
        whitelisted: !degen,
        offset: pageParam * PG_LIMIT,
        limit: PG_LIMIT
      }});
      return resp.data;
    },
    queryKey: ['pairs', search, degen, address],
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const onClose = () => {
    setSearch('');
    setOpen(false);
  };

  const handleSelection = (pair:PairInfo) => {
    setSearch('');
    navigate({ pathname: '/trade/swap', search: `?from=${pair?.first_token.contract_addr}&to=${pair?.second_token.contract_addr}` });
    onClose();
  };

  return (
    <>
      <Button
        className='bg-white/10 text-white/40 rounded-full self-center h-7 px-2 py-1 gap-2 flex items-center justify-between grow w-40'
        onClick={() => setOpen(true)}
      >
        Search
        <FaSearch className='text-white' />
      </Button>
      <Dialog open={isOpen} onClose={onClose} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black/30 backdrop-blur-md duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-dvh items-center justify-center text-center">
            <DialogPanel
              transition
              className="h-[90dvh] w-[90dvw] sm:w-[400px] sm:h-[600px] bg-background3 rounded-3xl transform shadow-xl transition-all data-[closed]:scale-95 data-[closed]:opacity-0 flex flex-col overflow-hidden"
            >
              <div className="p-4 flex flex-col items-start gap-4">
                <FaX className="text-white self-end" onClick={onClose}/>
                <div className="bg-gr2 rounded-full p-2 flex items-center justify-start gap-2 w-full cursor-pointer">
                  <FaSearch className='text-white'/>
                  <Input className="bg-transparent border-none focus:outline-none grow text-white" autoFocus value={search} onChange={(e) => setSearch(e.target.value.toLowerCase())}/>
                  <FaX className="text-white/60" onClick={() => setSearch('')}/>
                </div>
                <div className='flex flex-row w-full items-center justify-between gap-2'>
                  <ToggleSwitch value={degen} onChange={pairState.set.degen} label="Degen Mode" />
                  <div className="rounded-xl font-bold text-sm px-2 py-1 text-white/60 bg-background2 center gap-2">
                    Chain 1
                    <p className='bg-background4 rounded-lg font-bold px-2 py-1'>
                      Terra Classic
                    </p>
                  </div>
                </div>
              </div>
              <div className='h-full overflow-y-auto custom-scrollbar'>
                {map(data?.pages, (page) => map(page, (option) => (<PairOption key={option.contract_addr} pair={option} onSelect={() => handleSelection(option)}/>)))}
                <PaginationObserver hasNext={hasNextPage} addPage={fetchNextPage}/>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

/*
  
*/