import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from './base';
import { millisecondsInMinute } from 'date-fns/constants';
import { FarmingItemData } from 'types/farming';
import { walletState } from 'state/wallet-state';

export type FarmingResponse = FarmingItemData[];


export const useGetFarming = (whitelisted: boolean) => {
  return useSuspenseQuery<FarmingResponse>({
    queryKey: ['farming', {whitelisted}],
    queryFn: async () => {
      const { data } = await axios.get(`${baseUrl}/farming`, {params: { whitelisted }});
      return data;
    },
    refetchInterval: 5 * millisecondsInMinute
  });
};

export const useGetFarmingPrices = () => {
  return useQuery<Record<string, number>>({
    queryKey: ['farming-prices'],
    refetchInterval: 5 * millisecondsInMinute,
    queryFn: async () => {
      const {data} = await axios.get(`${baseUrl}/farming/prices`);
      return data;
    }
  });
};

export const useGetFarmingPairs = () => {
  return useQuery<Record<string, string>>({
    queryKey: ['farming-pairs'],
    queryFn: async () => {
      const {data} = await axios.get(`${baseUrl}/farming-by-pair-address`);
      return data;
    }
  });
};

export const useGetDevFarming = () => {
  return useSuspenseQuery<FarmingResponse>({
    queryKey: ['dev-farming', walletState.get.jwt()],
    queryFn: async () => {
      const { data } = await axios.get(`${baseUrl}/development/farming`, {
        headers: {
          Authorization: `Bearer ${walletState.get.jwt()}`,
        }
      });
      return data;
    },
    refetchInterval: 5 * millisecondsInMinute
  });
};


export const postUpdateDevFarm = async (contract_addr: string) => {
  const res = await axios.post(`${baseUrl}/development/farming/${contract_addr}/update`,
    {},
    {
      headers:{
        Authorization: `Bearer ${walletState.get.jwt()}`
      }
    },
  );
  return res.data;
};
