import { useVestingPermission } from 'api/terraport/vesting';
import { SwapIcon, ShipIcon, TradeIcon, GovernanceIcon, MoreListIcon } from 'ui/icons/NavbarIcons';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavItem, MultiNavItem } from 'types/routes';

export const useAppRoutes = () => {
  const {t} = useTranslation();
  const vesting = useVestingPermission();
  const routes = useMemo<Array<NavItem | MultiNavItem>>(() => [
    {
      title: t('common.trade'),
      pathname: '/trade',
      icon: SwapIcon,
    },
    {
      multipath: true,
      title: t('common.earn'),
      icon: ShipIcon,
      paths: compact([
        {
          title: t('common.staking'),
          multipath: false,
          pathname: '/staking'
        },
        {
          title: t('common.farming'),
          multipath: false,
          pathname: '/farming'
        },
        {
          title: t('common.launchpad'),
          pathname: '/launchpad',
        },
      ]),
    },
    {
      title: t('common.leaderboard'),
      mobileTitle: 'Lead',
      icon: TradeIcon,
      multipath: false,
      pathname: '/leaderboard'
    },
    {
      title: t('common.governance'),
      mobileTitle: 'Gov',
      icon: GovernanceIcon,
      multipath: false,
      pathname: '/governance',
    },
    {
      title: t('common.other'),
      icon: MoreListIcon,
      multipath: true,
      paths: compact([
        {
          title: t('common.development'),
          mobileTitle: 'Dev',
          icon: TradeIcon,
          multipath: false,
          pathname: '/development',
        },
        {
          title: t('routes.docs'),
          pathname: 'https://terraport.gitbook.io/terraport-docs/',
          openOtherPage: true,
        },
        {
          title: t('routes.risk_advice'),
          pathname: 'https://terraport.gitbook.io/terraport-docs/safety-and-security/safety-and-risk-advice',
          openOtherPage: true,
        },
        {
          hidden:() => !vesting.found,
          title: t('common.vesting'),
          pathname: '/vesting'
        },
      ])
    },
  ], [t]);
  const { pathname } = useLocation();
  const currentIndex = useMemo(() => routes.findIndex(
    r => r.multipath
      ? r.paths.find(sr => pathname.startsWith(sr.pathname))
      : pathname.startsWith(r.pathname) || r.secondaryPathname !== undefined && pathname.startsWith(r.secondaryPathname)
  ), [pathname]);
  return useMemo(() => ({
    routes, currentIndex
  }), [routes, currentIndex]);
};