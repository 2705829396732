import axios from 'axios';
import { baseUrl } from './base';
import { useAddress } from 'state/wallet-state';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

type VestingPermissionData = {
  address: string; // user wallet
  found: boolean;
  lists: {name: string; original_name:string; amount: number;}[]
}

const empty : VestingPermissionData = {
  address:'',
  found:false,
  lists:[]
};

const getVesting = async (addr: string) => {
  const { data } = await axios.get<VestingPermissionData>(`${baseUrl}/vesting/account/${addr}`);
  return data;
};

export const useVestingPermission = () => {
  const address = useAddress();
  const [hasAccess, setHasAccess] = useState<VestingPermissionData>(empty);
  console.log(hasAccess);
  useEffect(()=>{
    if(!address){
      setHasAccess(empty);
      return;
    }

    getVesting(address).then((value) =>{
      if(value){
        setHasAccess(value);
      }
    });
  },[address]);
  return hasAccess;
};

// non posso usarla perchè chiedo fuori dal context per useQuery
// export const useVestingPermission = (addr?: string) => {
//   return useQuery({
//     enabled: !!addr,
//     queryKey: ['vesting-permission', addr],
//     refetchInterval: 60000,
//     queryFn: async () => getVesting(addr ?? '')
//   });
// };
